/* Loader.css */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh; /* Prend toute la hauteur de la vue */
    flex-direction: column;
}

.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #26b901; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loader-container p {
    margin-top: 5px;
    font-size: 1.2rem;
    color: #26b901; /* Blue */
}
