/* Conteneur du formulaire */
.formulaire-container {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
}
.progress-container {
    width: 100%; /* Assure que le conteneur occupe toute la largeur */
  }
  
  .progress-text {
    font-size: 0.9rem;
    font-weight: bold;
    color: #555;
  }
  
/* Messages d'erreur */
.error,
.error + span {
    color: red;
    font-size: 12px;
}

.PhoneInput input.error {
    border-color: red;
}

.phone {
    margin: 0;
    padding: 0;
}

/* Champ de formulaire */
.form-control {
    height: 35px;
    padding: 5px 10px;
    font-size: 14px; 
}

.phone .form-control {
    height: 35px; /* Pour le PhoneInput */
}
/* Style spécifique pour les messages d'erreur */
.invalid-feedback {
    color: red; /* Red color for error messages */
    font-size: 0.875em; /* Optional: adjust font size */
    margin-top: 5px; /* Optional: space between input and error message */
  }
  